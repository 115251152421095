<template>
    <v-flex xs12 style="padding-bottom:15px!important;">
        <v-card @click.native="clicked()" :style="{'background-color': cardColor}" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
            <v-container fluid style="padding: 10px;">

                <v-card-text style="padding: 2px;">
                    <v-layout wrap>
                        <v-flex xs6>
                            <div style="font-size: 1.3em;">{{budgetItem.name}}</div>
                        </v-flex>
                        <v-flex xs6 style="text-align:right;">
                            <span>{{ budgetItem.type }}</span>
                        </v-flex>
                        <v-flex xs12 style="margin: 5px 0">
                            <v-progress-linear :value="value"
                                               :color="progressColor"
                                               height="15"
                                               rounded>
                                <template v-slot="{ value }">
                                    <strong>{{ value }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-flex>
                        <v-flex xs6 style="text-align:left;">
                            <v-layout wrap style="ma     rgin: 3px 0">
                                <v-flex xs12>
                                    <span style="font-size: .97em;">Spent: {{budgetItem.currency}} {{budgetItem.amountSpent}}</span>
                                </v-flex>
                                <v-flex xs12>
                                    <span style="font-size: .97em;">Budgeted: {{budgetItem.currency}} {{budgetItem.amount}}</span>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs6 style="text-align:right;">
                            <v-layout wrap style="margin: 3px 0">
                                <v-flex xs12>
                                    <span style="font-size: .97em;">Remaining</span>
                                </v-flex>
                                <v-flex xs12>
                                    <span style="font-size: 1.15em;"><strong>{{budgetItem.currency}} {{remaining}}</strong></span>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-dialog v-model="budgetItem.show" scrollable :fullscreen="$vuetify.breakpoint.smAndDown" v-if="$vuetify.breakpoint.smAndDown" hide-overlay transition="dialog-bottom-transition">
                    <ListItemData v-bind:lItem="budgetItem" v-on:EditItem="redirectToEditPage($event)" v-on:DeleteItem="DeleteItem($event)"></ListItemData>
                </v-dialog>

            </v-container>
        </v-card>
    </v-flex>
</template>

<script>
  import listItemData from './listItemData.vue'
    import { globalFunctions, formatMoney } from '../../javascript/globalFunctions'
  import { listItemFunctions } from '../../javascript/listItemManager'

  export default {
    components: {
      ListItemData: listItemData
    },
    props: ['lisItem'],
    data() {
    return {
        value: 0,
        remaining: 0,
        cardColor: '',
        progressColor: '',
        budgetItem: {
          id: 0,
          listType: '',
          name: '',
          account: '',
          type: '',
          amount: 0,
          amountSpent: 0,
          refillAmount: 0,
          date: '',
          currency: '',

          show: false
        }
      }
    },
    methods: {
        newPercentage: function (spent, total) {
            return (parseFloat(spent) / parseFloat(total)) * 100
        },
        remainingAmount: function () {
            return formatMoney((parseFloat(this.budgetItem.amount) - parseFloat(this.budgetItem.amountSpent)))
        },
        addColor: function () {
            if (this.value < 61) {
                this.cardColor = '#03A0211C'
                this.progressColor = '#4CAF50' //green 
            } else if (this.value > 60 && this.value < 91) {
                this.cardColor = '#9FB1001C'
                this.progressColor = '#F9A825' //yellow
            } else if (this.value > 90 && this.value < 100) {
                this.cardColor = '#B160001C'
                this.progressColor = '#E65100' //orange
            } else if(this.value > 99) {
                this.cardColor = '#B11A001C'
                this.progressColor = '#F44336' //red
            }
        },
      redirectToEditPage: function (itemId) {
        this.$emit('Edit', itemId)
      },
      DeleteItem: function (budgetItem) {
        this.$emit('Delete', budgetItem)
      },
      itemClicked: function (item) {
        this.$emit('clickedItem', item)
      },
      clicked: function () {
        this.budgetItem.show = !this.budgetItem.show
        this.itemClicked(this.budgetItem)
      },
    },
    beforeMount() {
        this.budgetItem = globalFunctions.stringify(listItemFunctions.conversionManager(this.lisItem.type, this.lisItem.data))

        this.budgetItem.name = globalFunctions.stringify(globalFunctions.capitalizeFirstLetter(this.budgetItem.name))

        this.value = formatMoney(this.newPercentage(this.budgetItem.amountSpent, this.budgetItem.amount))

        this.remaining = this.remainingAmount()

        this.addColor()

        this.budgetItem.amount = formatMoney(this.budgetItem.amount, 2, ".", ",")

        this.budgetItem.amountSpent = formatMoney(this.budgetItem.amountSpent, 2, ".", ",")
    }
  }

</script>

<style scoped>

    .flex {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .padContainer {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
</style>
             